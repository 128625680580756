import { SiteTypeContext } from "../../contexts/SiteTypeContext";
import useOpen from "../../core/hooks/useOpen";
import Track from "../../core/instrumentation";
import MobileNavigationHeader from "./MobileNavigationHeader";
import NavigationContext from "./NavigationContext";
import NavigationLink from "./NavigationLink";
import ViewWebsite from "./ViewWebsite";
import { navigationLinksPropType } from "./utils";
import { css, Global } from "@emotion/react";
import { Icon, styleUtils, TextLink, theme } from "@minted/minted-components";
import camelCase from "lodash/camelCase";
import React, { useContext } from "react";

const { rem } = styleUtils;

const navigationLinkStyles = css`
  ${theme.typeStyles.bodySmallStyles};
  ${theme.typeStyles.bodyBoldStyles};
  align-items: center;
  border-left: ${rem(3)} solid transparent;
  color: ${theme.colors.gray700};
  cursor: pointer;
  display: flex;
  height: ${rem(48)};
  padding-left: ${theme.spacing.x6};

  :hover {
    background: rgba(230, 230, 230, 0.25);
  }
`;

const navigationLinkSelectionStyles = ({ isActive }) => css`
  text-transform: capitalize;
  ${navigationLinkStyles}
  ${theme.typeStyles.bodyBoldStyles}
  ${isActive && `border-left-color: ${theme.colors.gray700};`}
  background: ${isActive ? "rgba(230,230,230,0.5)" : "transparent"};
`;

const mintedUrlLinkStyles = css`
  ${theme.typeStyles.bodyExtraSmallStyles}
  align-items: center;
  border-left: ${rem(3)} solid transparent;
  color: ${theme.colors.gray700};
  cursor: pointer;
  display: flex;
  height: ${rem(32)};
  padding-left: ${theme.spacing.x6};
  text-decoration: none;

  :hover {
    background: rgba(102, 102, 102, 0.25);
  }
`;

const openNavContainerStyles = css`
  background: ${theme.colors.gray100};
  display: flex;
  flex-direction: column;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 9;
`;

const navigationLinkContainerStyles = css`
  border-bottom: ${rem(1)} solid ${theme.colors.gray300};
  padding-bottom: ${theme.spacing.x3};
  padding-top: ${theme.spacing.x3};
`;

const navigationLogoutContainerStyles = css`
  padding-bottom: ${theme.spacing.x3};
  padding-top: ${theme.spacing.x3};
`;

const navigationContainerOverflowScrollStyles = css`
  overflow-y: scroll;
`;

const propTypes = {
  navigationLinks: navigationLinksPropType,
};

const MobileNavigation = ({ navigationLinks }) => {
  const { mintedUrls } = useContext(NavigationContext);

  const { close, isOpen, toggle } = useOpen();

  const { isOnlineInvitationSite } = useContext(SiteTypeContext);

  return (
    <div css={isOpen && openNavContainerStyles}>
      {isOpen && (
        <Global
          styles={css`
            body {
              overflow: hidden;
            }
          `}
        />
      )}

      <MobileNavigationHeader isOpen={isOpen} toggleMenu={toggle} />

      {isOpen && (
        <div css={navigationContainerOverflowScrollStyles}>
          <div css={navigationLinkContainerStyles} data-cy="navigationMenu">
            {navigationLinks.map(({ name, routeName }) => (
              <NavigationLink key={routeName} routeName={routeName}>
                {({ isActive, onNavigate }) => (
                  <div
                    css={navigationLinkSelectionStyles({
                      isActive,
                    })}
                    data-cy={`${camelCase(name)}Tab`}
                    onClick={() => {
                      Track.emit({
                        event: "click_tabs",
                        value:
                          routeName === "guests.all" ? "guests" : routeName,
                      });
                      onNavigate();
                      close();
                    }}
                  >
                    {name}
                  </div>
                )}
              </NavigationLink>
            ))}

            <ViewWebsite>
              {({ navigateToWeddingWebsite }) => (
                <div css={navigationLinkStyles} data-cy="viewYourWebsiteLink">
                  <TextLink
                    icon={Icon.types["open-in-new"]}
                    iconAlignment={TextLink.textLinkIconAlignments.left}
                    onClick={navigateToWeddingWebsite}
                    size="small"
                    text={
                      isOnlineInvitationSite
                        ? "View Your Invite"
                        : "View Your Website"
                    }
                    type={TextLink.textLinkTypes.secondaryAlternate}
                  />
                </div>
              )}
            </ViewWebsite>
          </div>

          <div css={navigationLinkContainerStyles}>
            <a
              css={mintedUrlLinkStyles}
              data-cy="myAccountLink"
              href={mintedUrls["my-account"]}
            >
              My Account
            </a>
            <a css={mintedUrlLinkStyles} href={mintedUrls.help}>
              Website Help
            </a>
            <a css={mintedUrlLinkStyles} href={mintedUrls.terms}>
              Terms & Conditions
            </a>
            <a css={mintedUrlLinkStyles} href={mintedUrls.privacy}>
              Privacy Policy
            </a>
          </div>
          <div css={navigationLogoutContainerStyles}>
            <a
              css={mintedUrlLinkStyles}
              data-cy="logoutLink"
              href={mintedUrls.logout}
            >
              Logout
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

MobileNavigation.propTypes = propTypes;

export default MobileNavigation;
