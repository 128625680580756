import config from "config";
import PropTypes from "prop-types";

export const navigationLinksPropType = PropTypes.arrayOf(
  PropTypes.shape({
    name: PropTypes.string.isRequired,
    routeName: PropTypes.string.isRequired,
  }),
);

export const navigateToMinted = () => {
  window.location.assign(config.get("mintedBaseUrl"));
};
