import { SiteTypeContext } from "../../contexts/SiteTypeContext";
import useElementProp from "../../core/hooks/useElementProp";
import useScrollY from "../../core/hooks/useScrollY";
import FullNavigation from "./FullNavigation";
import MobileNavigation from "./MobileNavigation";
import SimplifiedMobileNavigation from "./SimplifiedMobileNavigation";
import SimplifiedNavigation from "./SimplifiedNavigation";
import { css } from "@emotion/react";
import { useMediaQueryState } from "@minted/minted-components";
import PropTypes from "prop-types";
import React, { Fragment, useContext, useRef } from "react";

const propTypes = {
  isMultisiteDashboard: PropTypes.bool,
};

const SiteNavigation = ({ isMultisiteDashboard }) => {
  const ref = useRef();

  const mediumMediaQueryState = useMediaQueryState({
    mediaQuerySize: "medium",
  });
  const isMobile = mediumMediaQueryState === "BELOW";

  const { isScrolledBelow } = useScrollY(32);

  const elementHeight = useElementProp({
    propName: "offsetHeight",
    ref,
  });

  const { isHolidaySite, isOnlineInvitationSite, isWeddingSite } =
    useContext(SiteTypeContext);
  let navigationLinks = [];

  if (isWeddingSite) {
    navigationLinks = [
      {
        name: "Dashboard",
        routeName: "dashboard",
      },
      {
        name: "Settings",
        routeName: "settings",
      },
      {
        name: "Designs",
        routeName: "designs",
      },
      {
        name: "Site Editor",
        routeName: "pages",
      },
      {
        name: "Guests",
        routeName: "guests.all",
      },
    ];
  } else if (isOnlineInvitationSite) {
    navigationLinks = [
      {
        name: "Settings",
        routeName: "settings",
      },
      {
        name: "Design Invite",
        routeName: "pages",
      },
      {
        name: "Manage Guests",
        routeName: "guests",
      },
    ];
  }

  return (
    <Fragment>
      <div
        css={css`
          height: ${elementHeight}px;
        `}
      />

      <div
        css={css`
          position: fixed;
          top: 0;
          width: 100%;
          z-index: 11;
        `}
        ref={ref}
      >
        {isMobile ? (
          isMultisiteDashboard || isHolidaySite ? (
            <SimplifiedMobileNavigation
              isMultiSiteDashboard={isMultisiteDashboard}
            />
          ) : (
            <MobileNavigation navigationLinks={navigationLinks} />
          )
        ) : isMultisiteDashboard || isHolidaySite ? (
          <SimplifiedNavigation isMultiSiteDashboard={isMultisiteDashboard} />
        ) : (
          <FullNavigation
            isCollapsed={isScrolledBelow}
            navigationLinks={navigationLinks}
          />
        )}
      </div>
    </Fragment>
  );
};

SiteNavigation.propTypes = propTypes;

export default SiteNavigation;
