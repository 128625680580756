import RootStyles from "./core/components/RootStyles";
import { SentryBoundary } from "./core/components/SentryBoundary";
import { setMonetateCustomVariables } from "./monetate/utils";
import AuthenticationPage from "./site-picker/components/Auth/AuthenticationPage";
import SitePicker from "./site-picker/components/SitePicker";
import config from "config";
import React from "react";
import { render } from "react-dom";
import ReactGA from "react-ga";

ReactGA.initialize(config.get("hostGATrackingID"));

const data = window.__DATA__;

window.history.replaceState(null, "", "/");

// ported from legacy base template
const initData = {
  mintedUrls: {
    backboneBaseUrl: `${config.get("mintedBaseUrl")}/backbone`,
    cart: `${config.get("mintedBaseUrl")}/cart`,
    "contact-us": `${config.get("mintedBaseUrl")}/contact-us`,
    feedback: `${config.get("mintedBaseUrl")}/feedback`,
    help: `${config.get("mintedBaseUrl")}/help`,
    logout: `${config.get("mintedBaseUrl")}/logout`,
    "my-account": `${config.get("mintedBaseUrl")}/my-account`,
    "my-saved-designs": `${config.get("mintedBaseUrl")}/my-saved-designs`,
    privacy: `${config.get("mintedBaseUrl")}/privacy`,
    terms: `${config.get("mintedBaseUrl")}/terms`,
  },
};

const navBarProps = {
  mintedUrls: initData.mintedUrls,
  userFirstName: "",
};

const monetateSKUMap = data.map((site) => {
  if (site.isWedding) {
    return {
      name: "weddingSiteProductCode",
      value: site.mintedProductSku,
    };
  } else if (site.isHoliday) {
    return {
      name: "holidaySite",
      value: true,
    };
  } else if (site.isOnlineInvitation) {
    return {
      name: "onlineInvitationSite",
      value: site.mintedProductSku,
    };
  }
});

setMonetateCustomVariables(monetateSKUMap, "minted-website-picker");

if (data.length > 0) {
  render(
    <SentryBoundary>
      <RootStyles>
        <SitePicker navBarProps={navBarProps} sites={data} />
      </RootStyles>
    </SentryBoundary>,
    document.getElementById("app"),
  );
} else {
  render(
    <SentryBoundary>
      <RootStyles>
        <AuthenticationPage />
      </RootStyles>
    </SentryBoundary>,
    document.getElementById("app"),
  );
}
