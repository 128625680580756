import { SiteTypeContext } from "../../contexts/SiteTypeContext";
import MintedLogo, { logoColors } from "./MintedLogo";
import { MintedWeddingsLogo } from "./MintedWeddingsLogo";
import { navigateToMinted } from "./utils";
import { css } from "@emotion/react";
import { Icon, IconButton, styleUtils, theme } from "@minted/minted-components";
import React, { Fragment, useContext } from "react";

const { rem } = styleUtils;

const mintedLogoWrapper = css`
  :hover {
    cursor: pointer;
  }
`;

const navigationHeaderContainerStyles = css`
  align-items: center;
  background: ${theme.colors.gray100};
  border-bottom: ${rem(1)} solid ${theme.colors.gray300};
  box-sizing: border-box;
  color: ${theme.colors.gray700};
  cursor: pointer;
  display: flex;
  height: ${rem(56)};
  justify-content: space-between;
  padding-left: ${theme.spacing.x4};
  padding-right: ${theme.spacing.x4};
  width: 100%;
`;

const hiddenElementStyles = css`
  visibility: hidden;
`;

const navigateToMultiSiteDashboard = () => {
  window.location.assign("/dashboard");
};

export default ({ isOpen, toggleMenu }) => {
  const { isOnlineInvitationSite } = useContext(SiteTypeContext);

  return (
    <div css={navigationHeaderContainerStyles}>
      <Fragment>
        <div css={isOpen && hiddenElementStyles} data-cy="headerBackButton">
          <IconButton
            iconType={Icon.types["arrow-left"]}
            onClick={navigateToMultiSiteDashboard}
            size={IconButton.sizes.large}
            type={IconButton.types.secondary}
          />
        </div>
        <div css={mintedLogoWrapper} onClick={navigateToMinted}>
          {isOnlineInvitationSite ? (
            <MintedLogo
              alt="Minted Online Invitation"
              color={logoColors.black}
            />
          ) : (
            <MintedWeddingsLogo />
          )}
        </div>
        <div onClick={toggleMenu}>
          <Icon type={isOpen ? "close" : "menu"} />
        </div>
      </Fragment>
    </div>
  );
};
