import { SiteTypeContext } from "../../contexts/SiteTypeContext";
import ConfirmationModal from "../../minted-components-ports/ConfirmationModal";
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import React, { Fragment, useCallback, useContext } from "react";

const modalBodyStyles = css`
  list-style-position: inside;
  padding-left: 0;
`;

const DeleteSiteModal = ({ close, domainIsCustom, isOpen, onDeleteSite }) => {
  const onSubmit = useCallback(() => {
    onDeleteSite();
    close();
  }, [close, onDeleteSite]);
  const { isHolidaySite, isOnlineInvitationSite, isWeddingSite } =
    useContext(SiteTypeContext);
  let warningText = "";

  if (isWeddingSite || isOnlineInvitationSite) {
    warningText = `Are you sure you want to delete your ${isWeddingSite ? "wedding website" : "invitation"}?`;
  } else if (isHolidaySite) {
    warningText =
      "Deleting your website will remove all of your content and photos and your card recipients will no longer be able to visit your site. Are you sure you want to delete your website?";
  }

  return (
    <ConfirmationModal
      alertPrimaryText={
        isOnlineInvitationSite
          ? "Delete your invitation"
          : "Delete your website"
      }
      isDeleteModal={true}
      onCloseClick={close}
      onSubmit={onSubmit}
      open={isOpen}
    >
      {(isWeddingSite || isOnlineInvitationSite) && (
        <Fragment>
          <div>
            Deleting your {isWeddingSite && "wedding website "}
            {isOnlineInvitationSite && "invitation "}
            will:
          </div>
          <ul css={modalBodyStyles}>
            <li>Remove all of your content and photos.</li>
            {isWeddingSite && !domainIsCustom && (
              <li>Release your URL so others can use it.</li>
            )}
            <li>Remove guest lists and all RSVP responses.</li>
          </ul>
        </Fragment>
      )}
      <div>{warningText}</div>
    </ConfirmationModal>
  );
};

DeleteSiteModal.propTypes = {
  close: PropTypes.func.isRequired,
  domainIsCustom: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onDeleteSite: PropTypes.func.isRequired,
};

export default DeleteSiteModal;
