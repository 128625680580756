import { css } from "@emotion/react";
import { Loader } from "@minted/minted-components";
import PropTypes from "prop-types";
import { React } from "react";

const fitToParent = css`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const image = css`
  width: 100%;
  height: auto;
`;

const grayscaleStyles = css`
  ${image};
  filter: grayscale(1);
  -webkit-filter: grayscale(1);
`;

const ImageWrapper = ({ children, siteUrl }) => {
  if (siteUrl) {
    return (
      <a css={fitToParent} href={siteUrl}>
        {children}
      </a>
    );
  }

  return <div css={fitToParent}>{children}</div>;
};

const SiteCardImage = ({ isLoading, sectionType, siteImage, siteUrl }) => {
  return (
    <ImageWrapper siteUrl={siteUrl}>
      {isLoading ? (
        // eslint-disable-next-line react/style-prop-object
        <Loader size="medium" style="dark" type="indeterminate" />
      ) : (
        <img
          alt="Site preview"
          css={
            ["archived", "deleted"].includes(sectionType)
              ? grayscaleStyles
              : image
          }
          data-cy="sitePreview"
          src={siteImage}
        />
      )}
    </ImageWrapper>
  );
};

ImageWrapper.propTypes = {
  children: PropTypes.element.isRequired,
  siteUrl: PropTypes.string,
};

SiteCardImage.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  sectionType: PropTypes.string.isRequired,
  siteImage: PropTypes.string.isRequired,
  siteUrl: PropTypes.string.isRequired,
};

export default SiteCardImage;
