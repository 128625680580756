import OpenManager from "../../core/dashboard/OpenManager";
import { dropdownContainerStyles } from "../../neutral/styles/dropdown";
import DeleteSiteModal from "../../settings/components/DeleteSiteModal";
import { css } from "@emotion/react";
import { theme, Popover, Icon, styleUtils } from "@minted/minted-components";
import PropTypes from "prop-types";
import { React, Fragment } from "react";

const menuIconStyles = css`
  margin-left: auto;
  margin-right: ${theme.spacing.x2};
  max-width: 20%;
  height: 75%;
  ${styleUtils.flexCenter};
  border: none;
  background: white;
  color: ${theme.colors.gray700};
  cursor: pointer;
`;

const dropdownListStyles = css`
  padding: ${theme.spacing.base} ${theme.spacing.x4} ${theme.spacing.base}
    ${theme.spacing.x2};
  border-left: 3px solid transparent;
  white-space: nowrap;
  &:hover {
    border-left: 3px solid ${theme.colors.gray700};
    cursor: pointer;
  }
`;

const dropdownListItemStyles = css`
  ${theme.typeStyles.titleExtraSmallStyles};
  text-decoration: none;
  display: flex;
  align-items: center;
  &:hover {
    color: ${theme.colors.gray500};
  }
  svg {
    margin-right: ${theme.spacing.x2};
  }
`;

const destructiveDropdownListItemStyles = css`
  ${theme.typeStyles.titleExtraSmallStyles};
  color: ${theme.colors.negative800};
  display: flex;
  align-items: center;
  &:hover {
    color: ${theme.colors.negative600};
  }
  svg {
    margin-right: ${theme.spacing.x2};
  }
  span {
    color: ${theme.colors.negative800};
  }
`;

const dropdownTextStyles = css`
  ${theme.typeStyles.bodySmallStyles};
`;

const SiteCardDropdown = ({
  domainIsCustom,
  onDeleteSite,
  sectionType,
  urls,
}) => (
  <Fragment>
    <Popover.PopoverManager position="bottom-end">
      {({ contentRef, isOpen, targetRef, togglePopover }) => (
        <Fragment>
          <button
            css={menuIconStyles}
            data-cy="siteMenuButton"
            onClick={togglePopover}
            ref={targetRef}
          >
            <Icon type="dots-vertical" />
          </button>
          <Popover open={isOpen} ref={contentRef}>
            <Popover.PopoverContent>
              <div css={dropdownContainerStyles} data-cy="siteMenuContent">
                {sectionType === "active" && (
                  <div css={dropdownListStyles} data-cy="editButton">
                    <a css={dropdownListItemStyles} href={urls.edit}>
                      <Icon
                        size={Icon.sizes.extraSmall}
                        type={Icon.types.pencil}
                      />
                      <span css={dropdownTextStyles}>Edit</span>
                    </a>
                  </div>
                )}
                {sectionType === "active" && (
                  <div css={dropdownListStyles} data-cy="managePasswordButton">
                    <a css={dropdownListItemStyles} href={urls.settings}>
                      <Icon size={Icon.sizes.extraSmall} type="lock" />
                      <span css={dropdownTextStyles}>Manage Password</span>
                    </a>
                  </div>
                )}
                <div css={dropdownListStyles} data-cy="viewButton">
                  <a
                    css={dropdownListItemStyles}
                    href={urls.view}
                    rel="noreferrer"
                    target="_blank"
                  >
                    <Icon size={Icon.sizes.extraSmall} type="open-in-new" />
                    <span css={dropdownTextStyles}>View</span>
                  </a>
                </div>
                <OpenManager defaultState={false}>
                  {({ close, isOpen, open }) => {
                    const openModal = () => {
                      togglePopover();
                      open();
                    };

                    return (
                      <Fragment>
                        <div css={dropdownListStyles} data-cy="deleteButton">
                          <div
                            css={destructiveDropdownListItemStyles}
                            onClick={openModal}
                          >
                            <Icon
                              size={Icon.sizes.extraSmall}
                              type={Icon.types.trash}
                            />
                            <span css={dropdownTextStyles}>Delete</span>
                          </div>
                        </div>
                        <DeleteSiteModal
                          close={close}
                          domainIsCustom={domainIsCustom}
                          isOpen={isOpen}
                          onDeleteSite={onDeleteSite}
                        />
                      </Fragment>
                    );
                  }}
                </OpenManager>
              </div>
            </Popover.PopoverContent>
          </Popover>
        </Fragment>
      )}
    </Popover.PopoverManager>
  </Fragment>
);

SiteCardDropdown.propTypes = {
  domainIsCustom: PropTypes.bool.isRequired,
  onDeleteSite: PropTypes.func,
  sectionType: PropTypes.string.isRequired,
  urls: PropTypes.object.isRequired,
};

export default SiteCardDropdown;
